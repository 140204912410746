<template>
    <div class="border border-indigo-600 min-h-screen my-2" style="min-height: 1200px;">
        <div class="flex justify-between p-8">
            <div class="flex flex-col">
                <p>{{ clientInfo.name }}</p>
                <p>Org.nr {{ clientInfo.registrationNumber }}</p>
            </div>
            <p>x (x)</p>
        </div>
        <div class="px-8 py-8">
            <!-- <pre>{{ tableData }}</pre> -->
            <table>
                <thead v-if="data && data.period && data.previousPeriod">
                    <tr>
                        <th class="font-bold pb-4" width="450">{{ title }}</th>
                        <th class="font-bold text-right pb-4" width="50">Not</th>
                        <th class="font-bold break-normal text-right pb-4" width="100">
                            <p>{{ formatDate(data.period.endDate) }}</p>
                        </th>
                        <th class="font-bold break-normal text-right pb-4" width="100">
                            <p>{{ formatDate(data.previousPeriod.endDate) }}</p>
                        </th>
                    </tr>
                </thead>
                <tbody v-for="(row, index) in tableData" :key="index">
                    <tr>
                        <td class="break-normal" width="450">
                            <p :class="{ 'font-bold py-2': (row.name && row.name === row.name.toUpperCase()) || handleName(row.name), 'break-normal': row.name, 'pt-4': row.type === 1 }">
                                {{ row.name }}
                            </p>
                        </td>
                        <td class="text-right" width="50">
                            <p v-show="displayNotesNumber(row)">{{ row.notesNumber }}</p>
                        </td>
                        <td v-for="(amount, index) in getValues(row)" :key="index" class="text-right" width="100">
                            <div v-if="row.type !== 3">
                                <p v-if="amount == 0" :class="{ 'font-bold pb-2': row.name && row.name === row.name.toUpperCase() }">0</p>
                                <p v-else :class="{ 'font-bold pb-2': row.name && row.name === row.name.toUpperCase() }">{{ amount | swedishNumberFormat(true) }}</p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    props: {
        clientId: {
            type: String,
        },
        clientInfo: {
            type: Object,
        },
        data: {
            type: Object,
        },
        tableData: {
            type: Array,
        },
        title: {
            type: String,
        },
    },

    methods: {
        formatDate(date) {
            if (!date) return " ";
            return moment(date).format("YYYY.MM.DD");
        },
        getValues(row) {
            return [row.periodRounded, row.previousPeriodRounded];
        },
        handleName(rowValue) {
            return rowValue === "Bundet eget kapital" || rowValue === "Fritt eget kapital";
        },
        displayNotesNumber(row) {
            return row.type === 5 && !this.isNameUppercase(row) && row.notesNumber !== 0;
        },
        isNameUppercase(row) {
            if (row && row.name && typeof row.name === "string") {
                return row.name === row.name.toUpperCase();
            } else {
                return false;
            }
        },
    },
};
</script>

<style scoped>
.text-right {
    text-align: right;
}
</style>
